import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Paragraph from "../../../../../components/paragraph"
import NotationSingle from "../../../../../components/notation-single"
import Grid from "../../../../../components/grid"
import CourseFooterNext from "../../../../../components/course-footer-next"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import EvaluationStacked from "../../../../../components/evaluation-stacked"
import SolutionsStacked from "../../../../../components/solutions-stacked"
import Divider from "../../../../../components/divider"

const Page = () => {
  const { getTask, answerStatusForArray, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const taskProps = {
    courseId: "versteckte-ordnung",
    chapterId: "02-ordnung",
    taskId: "feuerreiter",
  }
  const answers = answerStatusForArray(taskProps)
  const statistics = getStatsForTaskWithArray(taskProps)
  const solutions = getTask(taskProps).solution

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/02-ordnung/ottos-mops"
        />
      }
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <FeedbackHeaderProgress
            variant="solution"
            correct={statistics.correct}
            total={statistics.correct + statistics.missed}
          />
          <Grid columns={[1, 2]}>
            <EvaluationStacked answers={answers} />
            <SolutionsStacked solutions={solutions} />
          </Grid>
          <Divider size={4} />
          <Grid columns={[1, 2]} reverse={[false, true]}>
            <Stack>
              <Paragraph>
                Das Gedicht hat einen Kreuzreim. Das letzte Wort der 1. Zeile
                reimt sich auf das letzte Wort der 3. Zeile, das letzte Wort der
                2. Zeile auf das letzte Wort der 4. Zeile.
              </Paragraph>
              <Paragraph>
                Der Ausruf „Hinter’m Berg, / Hinter’m Berg“ wird in jeder
                Strophe wiederholt und hat so die Funktion eines Refrains.
              </Paragraph>
              <Paragraph>
                Außerdem hat jede Strophe des Gedichts 10 Verse.
              </Paragraph>
            </Stack>
            <PaperStack>
              <Poem size={[3, 3, 4]}>
                <span>
                  Sehet ihr am{" "}
                  <NotationSingle type="circle" color="winter">
                    Fensterlein
                  </NotationSingle>
                </span>
                <span>
                  Dort die rothe Mütze{" "}
                  <NotationSingle type="circle" color="autumn">
                    wieder
                  </NotationSingle>
                  ?
                </span>
                <span>
                  Nicht geheuer muß es{" "}
                  <NotationSingle type="circle" color="winter">
                    sein
                  </NotationSingle>
                  ,
                </span>
                <span>
                  Denn er geht schon auf und{" "}
                  <NotationSingle type="circle" color="autumn">
                    nieder
                  </NotationSingle>
                  .
                </span>
                <span>Und auf einmal welch Gewühle</span>
                <span>Bei der Brücke, nach dem Feld!</span>
                <span>Horch! Das Feuerglöcklein gellt:</span>
                <NotationSingle color="spring">
                  <span>Hinter’m Berg,</span>
                </NotationSingle>
                <NotationSingle color="spring">
                  <span>Hinter’m Berg</span>
                </NotationSingle>
                <NotationSingle color="spring">
                  <span>Brennt es in der Mühle!</span>
                </NotationSingle>
                <br />
                <span>Schaut! da sprengt er wüthend schier</span>
                <span>Durch das Thor, der Feuerreiter,</span>
                <span>Auf dem rippendürren Thier,</span>
                <span>Als auf einer Feuerleiter!</span>
                <span>Querfeldein! Durch Qualm und Schwüle</span>
                <span>Rennt er schon, und ist am Ort!</span>
                <span>Drüben schallt es fort und fort:</span>
                <NotationSingle color="spring">
                  <span>Hinter’m Berg,</span>
                </NotationSingle>
                <NotationSingle color="spring">
                  <span>Hinter’m Berg</span>
                </NotationSingle>
                <NotationSingle color="spring">
                  <span>Brennt es in der Mühle!</span>
                </NotationSingle>
                <br />
                <span>...</span>
              </Poem>
            </PaperStack>
          </Grid>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
